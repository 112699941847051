module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Greenstone Wellness","short_name":"Greenstone Wellness","start_url":"/","background_color":"#0E870B","theme_color":"#0E870B","display":"minimal-ui","icon":"src/images/greenstone-favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans Condensed:300","Open Sans:400,600,700,800","Roboto:300,300i,400,400i,500,700,900","Yesteryear"]},"custom":{"families":["Futura","Refreshen and Softening"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
